<template>
  <div class="container">
    <h2>Enabling drafts...</h2>
  </div>
</template>

<script>
  import store from '../store'

  export default {
    name: 'ShowDrafts',
    mounted() {
      store.state.showDrafts = true
      this.$router.push('/')
    }
  }
</script>
